export default {
  processKanbanIsDisabledInIgs: 'O Kanban de processos não está habilitado no IGS. Habilite para usar este recurso.',
  client: 'Cliente',
  prospect: 'Prospect',

  navigation: {
    description: {
      salesForceAdditionalInfo: '(Força de Vendas)',
    },
    salesForceAdditionalInfo: 'Informações adicionais',
    attendance: 'Atendimento',
    attachments: 'Anexos',
    sendEmail: 'Enviar E-mail',
    dashboard: 'Painel do {clientType}',
    financialInformation: 'Informações Financeiras',
    calendar: 'Agenda de Atividades',
    scheduling: 'Agendamentos',
    processes: 'Processos',
    orders: 'Pedidos',
    sales: 'Vendas por Coleção',
    visitsMade: 'Visitas Realizadas',
    clientsManagement: 'Gestão de clientes',
    emails: 'E-mails',
    attendances: 'Linha do Tempo',
    chats: 'Chats',
    calls: 'Ligações',
    messages: {
      errorIntegrationClientNotFound:
        'Cliente não integrado ao GEOvendas. Por gentileza, entre em contato com seu departamento de TI pois é possível que o cadastro desse cliente no ERP esteja em desacordo as regras de integração de clientes do GEOvendas.',
    },
    prospectCityClients: 'Clientes Concorrentes',
  },

  view: {
    index: {
      advancedSearch: 'Busca Avançada',
      client: 'Cliente',
      prospect: 'Prospect',
      newProspect: 'Novo Prospect',
      wouldLikeToChangeToClient: 'Deseja mudar a consulta para cliente? A consulta atual será perdida',
      wouldLikeToChangeToProspect: 'Deseja mudar a consulta para prospect? A consulta atual será perdida',

      dialog: {
        sendEmail: {
          title: 'Novo e-mail',
        },
        advancedSearch: {
          title: 'Busca avançada',
        },
        attachments: {
          title: 'Anexos',
        },
        prospect: {
          title: 'Novo Prospect',
        },
        attendance: {
          title: 'Incluir {clientType} na fila de atendimento',
        },
      },
    },

    home: {
      selectClientToShowInfo: 'Selecione um cliente para visualizar os dados',
      selectProspectToShowInfo: 'Selecione um prospect para visualizar os dados',
    },

    dashboard: {
      schedule: 'Agenda',
      dateAndHour: 'Data e hora',
      attendant: 'Atendente',
      historyType: 'Tipo de Histórico',
      name: 'Nome',
      lastStep: 'Última Etapa',
      email: 'E-mail',
      telephone: 'Telefone',
      whatsapp: 'WhatsApp',
      type: 'Cargo',
      actions: 'Ações',
      activities: 'Atividades',
      agenda: 'Agendas',
      processes: 'Processos',
      prospectProcesses: 'Processos do painel do prospect',
      prospectChats: 'Chats do painel do prospect',
      consultProspectProcesses: 'Consultar processos de quando era prospect',
      consultProspectChats: 'Consultar chats de quando era prospect',
      consultProspectAttachments: 'Ver anexos de quando era prospect',
      status: 'Status',
      statusByEventColor: {
        eventRed: 'Atrasado',
        eventBlue: 'Aberto',
        eventGreen: 'Baixado',
        eventPurple: 'Outro Atendente',
        eventYellow: 'Reagendamento',
      },
      contact: {
        title: 'Contato',
        status: {
          title: 'Situação',
          active: 'Ativo',
          inactive: 'Inativo',
          activeContacts: 'Contatos ativos',
          inactiveContacts: 'Contatos inativos',
          both: 'Ambos',
        },
        message: {
          isInactive: 'Este contato está inativo. Caso deseje conversar ative-o novamente.',
          sendToErp: 'Enviando contato para o ERP',
        },
      },

      messages: {
        prospectSuccessfullyDeleted: 'Prospect excluído com sucesso',
        prospectNotDeletedConstraint: 'Não foi possível excluir o prospect pois existem registros vinculados ao mesmo!',
        prospectNotDeleted: 'Não foi possível excluir o prospect, tente novamente!',
        detailNotAuthorized: 'Seu usuário não possui as liberações necessárias para detalhar processos dessa etapa!',
      },

      dialog: {
        sendEmail: {
          title: 'Novo e-mail',
        },
        contactForm: {
          newTitle: 'Novo contato',
          editTitle: 'Editar contato',
        },
        processKanban: {
          title: 'Kanban - {flow}',
        },
        activityView: {
          title: 'Agendas do {clientType}',
        },
        newActivityView: {
          title: 'Nova Agenda',
        },
        prospect: {
          title: 'Editar Prospect',
        },
        particularities: {
          saved: 'Particularidades do {clientType} salvas com sucesso!',
        },
      },
      responsibleAttendant: {
        success: 'Atendente responsável definido com sucesso!',
      },
    },

    order: {
      title: 'Painel do Cliente - Pedidos',
      selectOneOrMoreOrderToSentEmail: 'Selecione um ou mais pedidos para enviar por e-mail',
      clickHereToExportOrders: 'Clique aqui para exportar os pedidos para o Excel',
      sendOrderByEmailLimitExceed:
        'Você não pode enviar mais que 1 pedido por vez. Você possui {selected} selecionados | Você não pode enviar mais que {n} pedidos por vez. Você possui {selected} selecionados',
      filter: {
        searchOnOrders: 'Procurar em pedidos',
        issuancePeriod: 'Período de emissão',
      },
      grid: {
        orderNumber: 'Nr. pedido',
        origin: 'Origem',
        situation: 'Situação',
        issuanceDate: 'Data emissão',
        forecastBillingDate: 'Prev. faturamento',
        grossValue: 'Valor bruto',
        netValue: 'Valor líquido',
        canceledItems: 'Qtde. itens cancelados',
        balanceValue: 'Valor Saldo',
      },
      dialog: {
        details: {
          title: 'Detalhes do Pedido #{orderNumber}',
        },
        sendMail: {
          title: 'Pedido {orderNumber} - Enviar e-mail | Pedidos: {orderNumber} - Enviar e-mail',
        },
      },
    },

    financialInformation: {
      title: 'Painel do Cliente - Informações Financeiras',
      clickHereToExportContracts: 'Clique aqui para exportar os títulos para o Excel',
      print: 'Segunda Via',
      defaulting: 'Pendente',
      filter: {
        searchOnContracts: 'Procurar em títulos',
        issuancePeriod: 'Período de emissão',
        expirePeriod: 'Período de vencimento',
        onlyExpiredContracts: 'Apenas títulos vencidos',
        searchBySituation: 'Situação',
      },
      grid: {
        contractName: 'Título',
        holder: 'Portador',
        origin: 'Origem',
        situation: 'Situação',
        issuanceDate: 'Emissão',
        dueDate: 'Vencimento',
        debtSettlementDate: 'Baixa',
        defaultingDays: 'Atraso',
        netValue: 'Valor líquido',
      },
      card: {
        creditLimitBalance: 'Saldo do limite de crédito',
        creditLimit: 'Limite de crédito',
        orderPlusDocuments: 'Pedidos + títulos',
        defaultingDays: 'Dias inadimplente',
        overdueValue: 'Vencidos(Valor)',
        paidLateQuantity: 'Pagos com atraso',
      },
    },

    process: {
      title: 'Painel do {clientType} - Processos',
      clickHereToExportProcesses: 'Clique aqui para exportar os processos para o Excel',
      filter: {
        searchOnProcesses: 'Procurar em processos',
      },
      grid: {
        code: 'Código',
        flow: 'Fluxo',
        step: 'Etapa',
        date: 'Data',
        queue: 'Fila',
        action: 'Ação',
        reason: 'Motivo',
        result: 'Resultado',
        detailNotAuthorized: 'Seu usuário não possui as liberações necessárias para detalhar processos dessa etapa!',
      },
    },

    calendar: {
      title: 'Painel do {clientType} - Agenda',
    },

    activities: {
      title: 'Painel do Cliente - Atividades',
      grid: {
        schedule: 'Agenda',
        dateAndHour: 'Data e hora',
        attendant: 'Atendente',
        historyType: 'Tipo de Histórico',
      },
      message: {
        emptyProcessId: 'Não foi encontrado o ID do processo nos query parameters da URL',
      },
      dialog: {
        calendarEventForm: {
          newTitle: 'Novo evento',
          editTitle: 'Editar evento',
        },
      },
    },

    visitsMade: {
      title: 'Painel do Cliente - Visitas Realizadas',
      clickHereToExportVisits: 'Clique aqui para exportar as visitas para o Excel',
      status: {
        evaluated: 'Avaliada',
        underEvaluation: 'Em avaliação',
      },
      filter: {
        searchOnVisits: 'Procurar em visitas',
        visitPeriod: 'Período da visita',
        status: 'Situação',
      },
      grid: {
        visit: 'Visita',
        date: 'Data',
        situation: 'Situação',
        tripReport: 'Rel. viagem',
        description: 'Descrição',
      },
    },

    sales: {
      title: 'Painel do Cliente - Vendas por Coleção',
      clickHereToExportSales: 'Clique aqui para exportar as vendas para o Excel',
      filter: {
        searchOnSales: 'Procurar em vendas',
      },
      grid: {
        collectionName: 'Coleção',
        orderQuantity: 'Qtde. pedidos',
        grossValue: 'Valor bruto',
        netValue: 'Valor líquido',
        discount: 'Desconto',
        discountPercentage: '% Desconto',
        commission: 'Comissão',
        commissionPercentage: '% Comissão',
        paymentTermAverage: 'Prazo médio',
        averageTicket: 'Ticket médio',
        itemsAverage: 'Média de itens',
        itemsTotal: 'Total itens',
        itemsCancelled: 'Itens cancelados',
        itemsTotalFinal: 'Total itens final',
        totalItemsQuantity: 'Qtde. total de itens',
        avaregeTicketPc: 'Ticket médio itens',
      },
      card: {
        avgOfOrderQuantity: 'Média pedidos por coleção',
        avgOfNetValue: 'Valor médio por coleção',
        avgOfAverageTicket: 'Ticket médio por coleção',
        avgOfDiscountAverage: 'Desconto médio',
        avgOfItemsAverage: 'Média de itens',
        avgOfPaymentTermAverage: 'Prazo médio',
      },
    },

    clientsManagement: {
      title: 'Painel do Cliente - Gestão de Clientes',
      clickHereToExportClientsManagement: 'Clique aqui para exportar a gestão de clientes para o Excel',
      filter: {
        searchOnClientsManagement: 'Procurar em gestão de clientes',
      },
      grid: {
        managementName: 'Gestão',
        situation: 'Situação',
        reason: 'Motivo',
        representant: 'Representante',
        justification: 'Justificativa',
        justificationDate: 'Data da justificativa',
        evaluation: 'Avaliação',
        evaluationDate: 'Data da avaliação',
        observation: 'Observação',
        justify: 'Justificar',
      },

      modaljustification: {
        reason: 'Motivo',
        observation: 'Observação',
        date: 'Data de retorno',
        success: 'Justificativa enviado com sucesso!',
      }
    },

    emails: {
      title: 'Painel do {clientType} - E-mails',
      clickHereToExportEmails: 'Clique aqui para exportar os e-mails para o Excel',
      origin: {
        contact: 'Contato',
        order: 'Pedido',
        calendar: 'Agenda',
        titulo: 'Título',
      },
      filter: {
        searchOnEmails: 'Procurar em e-mails',
        origin: 'Origem',
      },
      grid: {
        origin: 'Origem',
        date: 'Data e hora',
        subject: 'Assunto',
        description: 'Descrição',
        observation: 'Observação',
        recipients: 'Destinatários',
        attendant: 'Atendente',
        attachments: 'Anexos',
        groupArea: 'Grupo/Área',
        historyType: 'Tipo de histórico',
      },
      dialog: {
        sendEmail: {
          title: 'Novo e-mail',
        },
      },
      details: 'Detalhes do e-mail',
    },

    chats: {
      title: 'Painel do {clientType} - Chats',
      clickHereToExportConversations: 'Clique aqui para exportar as conversas para o Excel',
      filter: {
        periodType: 'Tipo de período',
        period: 'Período',
        openDate: 'Data início conversa',
        startDate: 'Data início atendimento',
        closingDate: 'Data de fechamento',
        status: 'Status',
        open: 'Aberto',
        closed: 'Fechado',
        contactType: 'Cargo',
        contact: 'Contato',
        department: 'Departamento',
        attendant: 'Atendente',
        historyType: 'Tipo de histórico',
        protocolNumber: 'Número de protocolo',
      },
      grid: {
        openDate: 'Data início conversa',
        startDate: 'Data início atendimento',
        lastAttendance: 'Último atendimento',
        contactType: 'Cargo',
        legalName: 'Razão social',
        contactName: 'Nome do contato',
        protocol: 'Protocolo',
        attendant: 'Atendente',
        department: 'Departamento',
        historyType: 'Tipo de histórico',
      },
      dialog: {
        history: {
          title: 'Mensagens da conversa - {protocol}',
        },
      },
    },

    calls: {
      title: 'Painel do {clientType} - Ligações',
      clickHereToExportCalls: 'Clique aqui para exportar as ligações para o Excel',
      grid: {
        startDate: 'Início',
        endDate: 'Fim',
        callType: 'Tipo ligação',
        resultType: 'Resultado',
        contactType: 'Tipo de contato',
        protocol: 'Protocolo',
        attendant: 'Atendente',
        contactNumber: 'Número',
        codClient: 'Cód. Cliente/Prospect',
        legalName: 'Razão social',
        contactName: 'Nome do contato',
        group: 'Grupo/Área',
        historyType: 'Tipo de histórico',
        description: 'Descricão',
      },
      filter: {
        period: 'Período',
        ativa: 'Ativa',
        result_ativa: 'Concluída',
        result_nao_sucedida: 'Não sucedida',
        result_engano_duvidas: 'Engano/Dúvidas',
        callType: 'Tipo de ligação',
        resultType: 'Resultado',
        contactType: 'Tipo contato',
        contactName: 'Nome do contato',
        codClient: 'Cód. Cliente/Prospect',
        historyType: 'Tipo de histórico',
        attendant: 'Atendente',
        contactNumber: 'Número',
        group: 'Grupo/Área',
        protocol: 'Protocolo',
        receptiva: 'Receptiva',
        retorno: 'Retorno',
        retorno_nao_sucedido: 'Retorno não sucedido',
        client: 'Cliente',
        prospect: 'Prospect',
        undefined: 'Desconhecido',
      },
      dialog: {
        history: {
          title: 'Ligação detalhada - Protocolo {protocol}',
        },
        export: {
          title: 'Exportação de Ligações',
          cancel: 'Cancelar',
          confirmExport: 'Exportar',
          exportAll: 'Exportar todas as páginas',
          exportCurrent: 'Exportar página atual',
        },
      },
    },

    closeChats: {
      title: 'Fechamento de conversas em massa',
      client: 'Cliente',
      prospect: 'Prospect',
      warningApplyFilters: 'Aplique os filtros e selecione as conversas que deseja fechar',
      closeReview: 'Revisão das conversas que serão fechadas',
      classifyClosingConversations: 'Classificação das conversas que serão fechadas',
      confirmBeforeClose: 'Confirmação',
      warningBeforeClose: 'Atenção! Ao Clicar em SALVAR, {conversations} conversas serão fechadas.',
      warningContinue: 'Deseja continuar?',
      deleteChats: 'Fechar conversas',
      clickHereToDeleteChats: 'Clique aqui para fechar as conversas selecionadas',
      filter: {
        openDate: 'Data início conversa',
        startDate: 'Data início atendimento',
        contactNumber: 'Número do contato',
        lastMessageDate: 'Última mensagem',
      },
      stepper: {
        previous: 'Anterior',
        next: 'Próxima',
        save: 'Salvar',
        cancel: 'Cancelar',
      },
    },

    attendance: {
      title: 'Painel do {clientType} -  Linha do Tempo',
      clickHereToExportAttendance: 'Clique aqui para exportar os atendimentos para o Excel',
      emptyItemsForClient: 'Não existem atendimentos para este cliente',
      filter: {
        searchOnAttendances: 'Procurar no histórico',
        attendancePeriod: 'Período',
        attendanceFlow: 'Fluxo',
      },
      origin: {
        email: 'Email',
        order: 'Pedido',
        calendar: 'Agenda',
        process: 'Processo',
        conversation: 'Conversa',
        surveynps: 'Pesquisa NPS',
      },
      grid: {
        date: 'Date e hora',
        origin: 'Origem',
        attendant: 'Atendente',
        title: 'Título',
        description: 'Descrição',
      },
      dialog: {
        details: {
          title: 'Detalhes do atendimento',
          email: 'Detalhes do e-mail',
        },
        chatHistory: {
          title: 'Mensagens da conversa - {protocol}',
        },
        npssurvey: {
          title: 'Detalhes pesquisa NPS',
          dateLabel: 'Data e hora envio:',
        },
        callHistory: {
          title: 'Ligação detalhada - {protocol}',
        },
      },
    },

    chatOverview: {
      title: 'WhatsApp - Visão Geral',
      inAttendance: 'Em atendimento',
      attendanceQueues: 'Filas de atendimento',
      totalOpenConversation: 'Total de conversas abertas: {total}',
      totalConversation: 'Total de conversas: {total}',
      filterOpenChatAttendants: 'Visualizar somente atendentes com conversas em andamento',
    },

    statistics: {
      filter: {
        period: 'Período',
        attendant: 'Atendente',
        representative: 'Representante',
        uf: 'UF',
        city: 'Município',
        visualization: 'Visualização',
        quantity: 'Quantidade',
        values: 'Valores',
        considerUserWhoEnterOrder: 'Considerar usuário digitador do Força de Vendas',
        integratorUser: 'Usuário integrador',
        orderOrigin: 'Origem do pedido',
      },
      labels: {
        details: 'Detalhes',
        totalClients: 'Total: {value} clientes',
        totalPieces: 'Total: {value} peças',
        totalOrders: 'Total: {value} pedidos',
        totalCurrencyReal: 'Total: {value} reais',
        averageDays: 'Média: {value} dias',
        averageMinutes: 'Média: {value} minutos',
        averagePercent: 'Média: {value} %',
        conversion: 'Conversão',
        contacted: 'Contatados',
        converted: 'Convertidos',
        averageTicket: 'Ticket médio',
        averageAttendanceTime: 'Tempo médio de atendimento',
        clientsContactedVsConverted: 'Clientes contatados x Convertidos',
        contactedClients: 'Clientes contatados',
        contactedClientsPerState: 'Clientes contatados por estado',
        contactedClientsPerCity: 'Clientes contatados por município',
        contactedClientsPerAttendant: 'Clientes contatados por atendente',
        convertedClientsPerState: 'Clientes convertidos por estado',
        convertedClientsPerCity: 'Clientes convertidos por município',
        convertedClientsPerAttendant: 'Clientes convertidos por atendente',
        averageTicketPerState: 'Ticket médio por estado',
        averageTicketPerCity: 'Ticket médio por município',
        openingOfNewClientsPerAttendant: 'Abertura de novos clientes por atendente',
        openingOfNewClientsPerState: 'Abertura de novos clientes por estado',
        openingOfNewClientsPerCity: 'Abertura de novos clientes por município',
        averageTicketPerRepresentative: 'Ticket médio por representante',
        averageDiscountPerState: 'Desconto médio por estado',
        averageDiscountPerCity: 'Desconto médio por município',
        averageDiscountPerRepresentative: 'Desconto médio por representante',
        ordersPerRepresentative: 'Pedidos por representante',
        ordersPerCollection: 'Pedidos por coleção',
        averagePaymentTermPerState: 'Prazo médio por estado',
        averagePaymentTermPerCity: 'Prazo médio por município',
        averagePaymentTermPerRepresentative: 'Prazo médio por representante',
        averageAttendanceTimePerAttendant: 'Tempo médio de atendimento por atendente',
        openingNewClientsPerState: 'Abertura de novos clientes por estado',
        openingNewClientsPerCity: 'Abertura de novos clientes por município',
        openingNewClientsPerAttendant: 'Abertura de novos clientes por atendente',
        insideSalesProcess: 'Processo Inside Sales',
      },
      abbreviations: {
        piece: 'PÇ',
        client: 'CLI',
        minute: 'MIN',
        currencyReal: 'R$',
        percentage: '%',
      },
    },

    chatReport: {
      title: 'Relatório de Chats',
      clickHereToExportChats: 'Clique aqui para exportar os chats para o Excel',
      filter: {
        periodType: 'Tipo de período',
        period: 'Período',
        openDate: 'Data início conversa',
        startDate: 'Data início atendimento',
        closingDate: 'Data de fechamento',
        status: 'Status',
        open: 'Aberto',
        closed: 'Fechado',
        originType: 'Tipo da conversa',
        template: 'Template',
        activeConversation: 'Ativa',
        receptiveConversation: 'Receptiva',
        contactType: 'Tipo de contato',
        client: 'Cliente',
        prospect: 'Prospect',
        mistake: 'Engano',
        contactName: 'Nome do contato',
        contactNumber: 'Número do contato',
        department: 'Fila',
        attendant: 'Atendente',
        historyType: 'Tipo de histórico',
        protocolNumber: 'Número de protocolo',
        undefined: '',
      },
      grid: {
        lastAttendance: 'Último atendimento',
        contactType: 'Tipo de contato',
        legalName: 'Razão social',
        cnpj: 'CNPJ',
        cellPhone: 'Celular',
        contactName: 'Nome do contato',
        originType: 'Tipo da conversa',
        template: 'Template',
        protocol: 'Protocolo',
        attendant: 'Atendente',
        department: 'Fila',
        historyType: 'Tipo de histórico',
        screeningHistory: 'Histórico Triagem',
        openDate: 'Data início conversa',
        startDate: 'Data início atendimento',
        closeDate: 'Data de fechamento',
        detailConversation: 'Detalhar conversa',
      },
      dialog: {
        history: {
          title: 'Mensagens da conversa - {protocol}',
        },
        export: {
          title: 'Exportação de Conversas',
          cancel: 'Cancelar',
          confirmExport: 'Exportar',
          exportAll: 'Exportar todas as páginas',
          exportCurrent: 'Exportar página atual',
        },
      },
    },

    emailReport: {
      title: 'Relatório de E-mails',
      clickHereToExportEmails: 'Clique aqui para exportar os e-mails para o Excel',
      filter: {
        period: 'Período',
        origin: 'Origem',
        destination: 'Destinatário',
        searchOnEmails: 'Procurar em e-mails',
        contactType: 'Tipo de contato',
        client: 'Cliente',
        prospect: 'Prospect',
        contact: 'Contato',
        order: 'Pedido',
        calendar: 'Agenda',
        groupArea: 'Grupo/Área',
        historyType: 'Tipo de histórico',
      },
      grid: {
        origin: 'Origem',
        date: 'Data e hora',
        contactType: 'Tipo de contato',
        legalName: 'Razão Social',
        subject: 'Assunto',
        description: 'Descrição',
        recipients: 'Destinatários',
        attendant: 'Atendente',
        attachments: 'Anexos',
        groupArea: 'Grupo/Área',
        historyType: 'Tipo de histórico',
      },
      dialog: {
        export: {
          title: 'Exportação de E-mails',
          cancel: 'Cancelar',
          confirmExport: 'Exportar',
          exportAll: 'Exportar todas as páginas',
          exportCurrent: 'Exportar página atual',
        },
      },
    },

    callReport: {
      title: 'Relatório de Ligações',
      clickHereToExportCalls: 'Clique aqui para exportar as ligações para o Excel',
      grid: {
        startDate: 'Início',
        endDate: 'Fim',
        callType: 'Tipo ligação',
        resultType: 'Resultado',
        contactType: 'Tipo de contato',
        protocol: 'Protocolo',
        attendant: 'Atendente',
        contactNumber: 'Número',
        codClient: 'Cód. Cliente/Prospect',
        legalName: 'Razão social',
        contactName: 'Nome do contato',
        group: 'Grupo/Área',
        historyType: 'Tipo de histórico',
        description: 'Descricão',
      },
      filter: {
        period: 'Período',
        ativa: 'Ativa',
        result_ativa: 'Concluída',
        result_nao_sucedida: 'Não sucedida',
        result_engano_duvidas: 'Engano/Dúvidas',
        callType: 'Tipo de ligação',
        resultType: 'Resultado',
        contactType: 'Tipo contato',
        clientPropsect: 'Cliente/Prospect',
        contactName: 'Nome do contato',
        codClient: 'Cód. Cliente/Prospect',
        historyType: 'Tipo de histórico',
        attendant: 'Atendente',
        contactNumber: 'Número',
        group: 'Grupo/Área',
        protocol: 'Protocolo',
        receptiva: 'Receptiva',
        retorno: 'Retorno',
        retorno_nao_sucedido: 'Retorno não sucedido',
        client: 'Cliente',
        prospect: 'Prospect',
        undefined: 'Desconhecido',
      },
      dialog: {
        history: {
          title: 'Ligação detalhada - Protocolo {protocol}',
        },
        export: {
          title: 'Exportação de Ligações',
          cancel: 'Cancelar',
          confirmExport: 'Exportar',
          exportAll: 'Exportar todas as páginas',
          exportCurrent: 'Exportar página atual',
        },
      },
    },

    salesForceAdditionalInfo: {
      title: 'Informações adicionais do Força de Vendas',
      info:
        'As informações exibidas nesta tela podem ser cadastradas ou modificadas somente no produto Força de Vendas.',
      noAttachment: 'Anexo não inserido',
      noValue: 'Não informado',
    },

    attendantPanel: {
      title: 'Painel do atendente',
      headers: {
        actions: 'Ações',
        status: 'Situação',
        name: 'Nome',
        schedule: 'Agenda',
        historyType: 'Tipo de Histórico',
        client: 'Cliente/Prospect',
        lastStep: 'Última Etapa',
        dateAndHour: 'Data e hora',
        hour: 'Horário',
        dateLimit: 'Data limite da etapa',
      },
      chatResume: 'Resumo Whatsapp',
      delayed: 'Atrasado',
      delayedAttendances: 'Atividades atrasadas',
      delayedAttendancesCount: 'Você possui {n} atividade{plural} em atraso!',
      noDelayedAttendances: 'Parabéns! Sua agenda está em dia.',
      monthAttendances: 'Atividades do mês',
      monthResults: 'Resultados do mês',
      dayAttendances: 'Atividades do dia',
      noDayAttendances: 'Nenhuma atividade programada para o dia de hoje!',
      myProcesses: 'Meus processos',
      cards: {
        chatResume1: 'Tempo médio de atendimento',
        chatResume2: 'Conversas em atendimento',
        monthAttendances1: 'Agendas cadastradas',
        monthAttendances2: 'Agendas concluídas',
        monthResults1: 'Abertura de novos clientes',
        monthResults2: 'Conversão',
      },
      hints: {
        averageWppTime: 'Tempo médio de atendimento no WhatsApp do mês atual',
        newClients: 'Prospects transformados em clientes pelo CRM, no mês atual',
        conversion: `Número de clientes que tiveram o processo de vendas internas encerrado
        neste mês com resultado positivo, e porcentagem de conversão`,
      },
      noWhatsapp: 'O atendente não está configurado para utilizar WhatsApp',
      noAttendances: 'O atendente não está configurado para utilizar agenda',
      detailNotAuthorized: 'Seu usuário não possui as liberações necessárias para detalhar processos dessa etapa!',
    },

    globalCalendar: {
      title: 'Agenda 360',
      calendar: 'Calendário',
      list: 'Listagem',
      clickHereToExportEvents: 'Clique aqui para exportar os eventos para o Excel',
      dialog: {
        confirmReschedule: `Ao clicar em confirmar, a agenda será marcada como concluída,
        e você será direcionado para a tela de reagendamento. Deseja continuar?`,
      },
      filter: {
        type: 'Tipo',
        attendant: 'Atendente',
        representative: 'Representante',
        situation: 'Situação',
        consolidatedSale: 'Listar apenas agendamentos com vendas',
        client: 'Cliente',
        prospect: 'Prospect',
      },
      grid: {
        cnpj: 'CNPJ',
        event: 'Evento',
        client: 'Cliente',
        city: 'Cidade',
        attendant: 'Atendente',
        description: 'Descrição',
        date: 'Data',
        consolidatedSale: 'Efetuou venda',
      },
    },

    prospectCityClients: {
      title: 'Clientes por cidade',
      clickHereToExportInfo: 'Exportar',
      grid: {
        client: 'Cliente',
        representative: 'Representante',
        erpStatus: 'Situação ERP',
        managementStatus: 'Situação Gestão',
        address: 'Endereço',
        lastBoughtDate: 'Data Últ. Compra',
        lastBoughtValue: 'Valor Últ. Compra',
        lastBoughtQuantity: 'Qtde Pçs Últ. Compra',
        totalOrders: 'Qtde Total pedidos efetuados',
        averageTicket: 'Ticket Médio valor',
        averageQuantity: 'Média de peças',
      },
      filter: {
        representative: 'Representante',
        lastBoughtPeriod: 'Data Últ. Compra',
        erpStatus: 'Situação ERP',
        managementStatus: 'Situação Gestão de Clientes',
      },
      card: {
        activeClients: 'Qtde Clientes Ativos',
        nearestActiveClient: 'Cliente ativo mais próximo',
        nearestActiveClientDistance: 'Distância Cliente ativo mais próximo',
        mostRecentOrderActiveClient: 'Cliente ativo com pedido mais recente',
        mostRecentOrderActiveClientDistance: 'Distância do cliente ativo com pedido mais recente',
        mostRecentOrderValueActiveClient: 'Valor do pedido mais recente de cliente ativo',
        countyClient: 'Município',
        countyClientPopulation: 'População do Município',
        noClients: 'Sem clientes',
        noOrders: 'Sem pedidos',
        noInfo: 'Informação indisponível',
        nearestActiveClienteNotFound: 'Não foi possível localizar os dados do cliente mais próximo',
        noCounty: 'Sem Município',
        hint: {
          noGeoLocation: 'Funcionalidade disponível somente para clientes que possuem a GEOlocalização habilitada',
        },
      },
    },

    callCenterOverview: {
      title: 'Ligações - Visão Geral',
      inAttendance: 'Em Atendimento',
    },
  },

  // Components
  clientDetailsCard: {
    prospectConverted:
      'Esse prospect já foi convertido em cliente. Por gentileza, consulte-o na opção “Clientes” do painel de relacionamento',
    representative: 'Representante',
    attendant: 'Atendente',
    lastPurchase: 'Última compra',
    workPhone: 'Trabalho',
    cellPhone: 'Celular',
    postalCode: 'CEP',
    defaulting: 'Inadimplente',
    createdAt: 'Data de cadastro',
    stateInscription: 'Inscrição Estadual',
    availableCredit: 'Crédito disponível',
    particularities: 'Particularidades',
    description: 'Descrição',
    actions: {
      edit: 'Editar Prospect',
      delete: 'Excluir Prospect',
      transformToClient: 'Transformar em Cliente',
      searchAsClient: 'Consultar como cliente',
    },
    leadType: 'Tipo de lead',
    contactChannel: 'Forma de captação',
    responsibleAttendant: 'Atendente Responsável',
    registerOrigin: 'Origem de Cadastro',
  },

  orderDetails: {
    grossValue: 'Valor Bruto',
    netValue: 'Valor Líquido',
    totalValue: 'Valor Total',
    balanceValue: 'Valor Saldo',
    situation: 'Situação',
    discountValue: 'Valor Desconto',
    discount: 'Desconto',
    cnpj: 'CNPJ',
    stateInscription: 'Inscrição Estadual',
    representatives: 'Representante',
    commission: 'Comissão',
    tablePrice: 'Tabela de Preços',
    saleCondition: 'Condição de Venda',
    fiscalDocumentType: 'Tipo Nota',
    issuance: 'Emissão',
    billing: 'Faturamento',
    carrier: 'Transportadora',
    redeployment: 'Redespacho',
    value: 'Valor',
    nfeKey: 'Chave NFE',
    billed: 'Faturados',
    canceled: 'Cancelados',

    title: {
      generalInfo: 'Informações do Pedido - Capa',
      commercialData: 'Dados Comerciais',
      dates: 'Datas',
      transport: 'Transporte',
      observation: 'Observação',
      fiscalDocument: 'Nota Fiscal',
      itemsInOrder: 'Itens do Pedido',
    },

    dialog: {
      detailItems: {
        title: 'Itens do Pedido #{orderNumber}',
      },
      nfeViewer: {
        title: 'Nota Fiscal Eletrônica do Pedido #{orderNumber}',
      },
    },
  },

  orderItemsList: {
    searchOnItems: 'Procurar em itens',
    total: 'Total Geral',
    grossTotal: 'Total Bruto',
    netTotal: 'Total Líquido',
    issuedAndBalanceValue: 'Valor Faturado/Saldo',
    requestedItems: 'Itens do Pedido',
    invoiceItems: 'Itens Faturados',
    canceledItems: 'Itens Cancelados',

    grid: {
      productCode: 'SKU',
      collectionCode: 'Cód Coleção',
      wareHouse: 'Armazém',
      operationType: 'Tipo de Operação',
      productName: 'Nome',
      requestedQuantity: 'Pedidos',
      billingQuantity: 'Faturados',
      balanceQuantity: 'Saldo',
      canceledQuantity: 'Cancelados',
      unitGrossValue: 'Valor bruto',
      unitNetValue: 'Valor líquido',
      totalValue: 'Total',
    },
  },

  orderEmailView: {
    successfullySent: 'Pedido enviado com sucesso | Pedidos enviados com sucesso',
    defaultSubject: '{company} - Pedido {orderNumber} | {company} - Pedidos: {orderNumber}',
    defaultMessage: 'Olá!<br>Faça o download do(s) anexo(s) para visualizar os dados do(s) seu(s) pedido(s)',
  },

  tituloEmailView: {
    successfullySent: 'Título enviado com sucesso | Títulos enviados com sucesso',
    errorAwaitData: 'Aguarde os arquivos PDF selecionados serem carregados',
    errorSelectData: 'Selecione ao menos um título para enviar os PDFs por e-mail',
    defaultSubject: '{company} - Título {financialInfoNumber} | {company} - Títulos: {financialInfoNumber}',
    defaultMessage: 'Olá!<br>Faça o download do(s) anexo(s) para visualizar os dados do(s) seu(s) título(s)',
  },

  activityCalendarEventView: {
    successfullySent: 'Agenda enviada com sucesso',
    defaultSubject: '{company} - Agenda {title} - {date}',
    defaultMessage: 'Olá!<br>Faça o download do anexo para visualizar os dados do evento<br> {title} - {date}',
  },

  activityCalendarEventEmailView: {
    sendToTitle: 'Seleção do destinatário',
    sendToOptionsLabel: 'Para quem você deseja realizar o envio desse e-mail?',
    sendToAttendant: 'Atendente responsável pela agenda',
    sendToClient: 'Cliente',
    sendToProspect: 'Prospect',
    sendToAttendantAndClientProspect: 'Ambos',
    selectAEmailSubject: 'Selecione os destinatários vinculados ao {userType} que receberão esse e-mail'
  },

  emailView: {
    successfullySent: 'Email enviado com sucesso',
    classifyEmail: 'Classificação de e-mail',
  },

  contactForm: {
    youAreSureSyncRecord: 'As alterações ao contato serão descartadas. Tem certeza de que deseja sincronizar?',
    successfullySave: 'Contato salvo com sucesso',
    name: 'Contato',
    contactType: 'Cargo',
    email: 'E-mail',
    telephone: 'Telefone',
    whatsapp: 'WhatsApp',
    birthdate: 'Data de Nascimento',
    active: 'Ativo',
  },

  activityCalendar: {
    markAsClose: 'Marcar como concluído',
    consolidateSale: 'Efetuou venda?',
    status: {
      open: 'Aberto',
      reschedule: 'Reagendamento',
      complete: 'Baixado',
      delayed: 'Atrasado',
      holiday: 'Feriados',
      otherAttendant: 'Outros Atendentes',
    },
    dialog: {
      confirmReschedule: `Ao clicar em confirmar, a agenda será marcada como concluída,
        e você será direcionado para a tela de reagendamento. Deseja continuar?`,
      calendarEventForm: {
        newTitle: 'Nova agenda',
        editTitle: 'Editar evento',
        duplicateTitle: 'Duplicar evento',
        rescheduleTitle: 'Reagendamento',
      },
      activityCalendarEventView: {
        title: 'Confirmação envio de e-mail',
      },
    },
  },

  activityCalendarEventForm: {
    successfullySave: 'Evento salvo com sucesso',
    addAttachment: 'Adicionar anexo',
    addDescriptionOrAttachment: 'Adicionar descrição ou anexos',
    addObservation: 'Adicionar observação interna',
    selectAClientToAttachFiles: 'Selecione um cliente para inserir anexos',
    title: 'Título',
    startAt: 'Início',
    endAt: 'Fim',
    client: 'Cliente',
    prospect: 'Prospect',
    representative: 'Representante',
    origin: 'Origem',
    historyType: 'Tipo de Histórico',
    group: 'Grupo/Área',
    attendant: 'Responsável',
    closed: 'Concluído',
    allDay: 'Dia inteiro',
    consolidatedSale: 'Efetuou venda',
    observation: 'Observação interna',
    emailEvent: 'Salvar e enviar e-mail',
    emailEventHint: `Clicando nesse botão, você será direcionado para uma tela de confimação,
      onde poderá selecionar os endereços que receberão um e-mail com o conteúdo da agenda.`,
  },

  advancedSearch: {
    cnpj: 'CNPJ',
    cnpjCpf: 'CNPJ / CPF',
    companyName: 'Nome fantasia',
    clientCode: 'Código do cliente',
    legalName: 'Razão social',
    state: 'Estado',
    city: 'Cidade',
    leadType: 'Tipo de lead',
    contactChannel: 'Forma de captação',
    clickHereToExportClients: 'Clique aqui para exportar os clientes para o Excel',
    registerOrigin: 'Origem de Cadastro',

    grid: {
      clientCode: 'Código',
      registerOrigin: 'Origem de Cadastro',
      cnpj: 'CNPJ',
      companyName: 'Nome Fantasia',
      legalName: 'Razão Social',
      city: 'Cidade',
      state: 'UF',
      leadType: 'Tipo de lead',
      contactChannel: 'Forma de captação',
    },

    origemOptions: {
      crm: 'CRM',
      b2b: 'E-commerce B2B',
      forcaDeVendas: 'Força de Vendas',
      naoIdentificado: 'Não identificado',
    },
  },

  attachmentsViewer: {
    type: {
      frontage: 'Foto da fachada',
      order: 'Pedidos',
      visitation: 'Visitas',
      other: 'Outros anexos',
    },
    filter: {
      searchOnAttachments: 'Procurar em anexos',
      type: 'Tipo de anexo',
    },
    dialog: {
      dashboardUpload: {
        title: 'Upload de anexo: {area}',
      },
    },
    toolTip: {
      notAuthorizedToDeleteAttachments: 'Seu usuário não possui permissão para excluir anexos!',
    },
  },

  crmAttachmentsViewerDetailsDialog: {
    label: {
      date: 'Data',
      type: 'Tipo de anexo',
      description: 'Descrição',
    },
    type: {
      frontage: 'Foto da fachada',
      order: 'Pedidos',
      visitation: 'Visitas',
      other: 'Outros anexos',
    },
  },

  chat: {
    apiWhatsapp: 'API Whatsapp Business',
    closeConversation: 'Fechar conversa',
    blockNumber: 'Bloquear número',
    selectTemplate: 'Selecionar template',
    quickAnswer: 'Respostas prontas',
    saveContact: 'Salvar contato',
    showContact: 'Ver contato',
    photoAndVideo: 'Foto e Vídeo',
    overview: 'Visão Geral',
    document: 'Documento',
    transferConversation: 'Transferir conversa',
    historyConversation: 'Histórico da conversa',
    drawer: {
      messages: 'Mensagens',
      chat: 'Chat',
      queue: 'Fila',
      all: 'Tudo',
      filter: {
        contactType: {
          title: 'Tipo de Contato',
          client: 'Cliente',
          prospect: 'Prospect',
          notRegistered: 'Sem cadastro',
        },
        queue: {
          title: 'Fila',
        },
      },
    },
    messages: {
      error400:
        'Ocorreu uma instabilidade que consequentemente acabou corrompendo esse arquivo/mensagem. Por gentileza, solicite ao cliente que o mesmo reenvie este conteúdo.',
      error401:
        'Não foi possível enviar a mensagem pois a API que você está utilizando é inválida ou foi alterada! Por gentileza, entre em contato com nossa equipe de suporte!',
      error555:
        'O servidor do provedor da API precisou ser reiniciado, consequentemente impactando no envio dessa mensagem. Aguarde 2 minutos por gentileza e tente enviá-la novamente. Caso o erro persista, entre em contato com a equipe de atendimento. ',
      error470:
        'Falha no envio da mensagem porque a última resposta do cliente foi enviada há mais de 24 horas. Use o modelo de mensagem para responder.',
      error500:
        'Ocorreu uma instabilidade no momento do envio do arquivo. Por gentileza, tente enviá-lo novamente. Carro o erro persista, contate nossa equipe de suporte.',
      error1000: 'Formato de arquivo inválido',
      error1025: 'O tipo do arquivo não é suportado',
      error1013:
        'Não será possível enviar essa mensagem pois número de destino não possui uma conta de WhatsApp cadastrada.',
      noFundsError: 'Seu número não possui saldo! Acesse o hub da 360dialog para recarregar e enviar suas mensagens.',
      conversationBlocked:
        'Esta conversa está bloqueada até que o usuário responda ao template enviado. Aguarde resposta ou <a>envie um novo template</a>',
      conversationExpired: 'A janela de 24 horas dessa conversa expirou. <a>Envie um template</a> para desbloqueá-la.',
      conversationExpiredWithSentTemplate:
        'A janela de 24 horas dessa conversa expirou. Se já enviou <a>um template</a> aguarde até que o usuário responda.',
      conversationOpening: 'A conversa está sendo iniciada. Aguarde...',
      conversationAttendingBy: 'Conversa sendo atendida por {user}',
      conversationTransfering: 'A conversa está sendo transferida. Aguarde...',
      selectTemplateToStartConversation: 'Para iniciar a conversa, é necessário <a>selecionar um template</a>',
      recordingAudio: 'Capturando áudio',
      conversationSuscessfullyCreated:
        'Conversa criada com sucesso, aguarde um momento até que a mesma esteja disponível na sua fila',
      wouldYouSaveContactOrCloseAsMistake:
        'Atenção! Contato não cadastrado. Deseja salvar o contato ou fechar a conversa como engano?',
      thereIsNoActiveChatConversationToBeClosed:
        'A conversa não pode ser fechada, pois não existe uma janela de chat aberta',
      notAllowedTransferToSameAttendant: 'Não é possível transferir para o atual atendente da conversa',
      allowedLimitOfMediaBySendingWasAchieved: 'É permitido o envio de somente {limit} arquivos por vez',
      selectFileTypeIsInvalid: 'O arquivo {invalid} selecionado é inválido. Tipos permitidos: {types}',
      selectFileSizeIsInvalid:
        'O arquivo {invalid} selecionado é inválido. Tamanho máximo permitido para arquivos do tipo {type}: {limit}',
      limitMessageCharsExceeed:
        'O limite de {limit} caracteres por mensagem foi excedido. A mensagem que você está tentando enviar possui {quantity} caracteres',
      unsupportedTypeMessage: 'Formato de mensagem não suportado atualmente pela API do WhatsApp Business',
      couldNotLocateContent: 'A API do WhatsApp Business não conseguiu localizar essa mensagem - Erro no recebimento',
      imageUploadExtensionError: 'Você só pode adicionar imagens de extensão jpg, jpeg ou png',
      videoUploadExtensionError: 'Você só pode adicionar vídeos de extensão mp4 ou 3gpp',
      blockedNumber: 'Não é possível enviar mensagens para esse número pois ele foi bloqueado',
      blockNumberSuccess: 'O número {contactNumber} foi bloqueado com sucesso',
      blockNumberError:
        'Não foi possível realizar o bloqueio do número {contactNumber}<br>Por gentileza, tente novamente!',
    },
    status: {
      created: 'Falha no envio. Tente enviar novamente.',
      temporaryInError: 'Houve um erro ao enviar. Tente enviar novamente.',
      temporaryInErrorImage: 'Houve um erro ao enviar. O arquivo deve ser carregado novamente.',
    },
    retry: 'Tentar enviar novamente',
    label: {
      writeYourMessage: 'Escreva uma mensagem...',
    },
    dialog: {
      selectTemplate: {
        title: 'Selecione um template',
      },
      blockNumber: {
        title: 'Bloquear número {contactNumber}',
      },
      selectQuickAnswer: {
        title: 'Selecione uma resposta pronta',
      },
      contact: {
        title: 'Contato',
        types: 'Cargos',
      },
      closeConversation: {
        title: {
          close: 'Fechar conversa',
          transfer: 'Transferir conversa',
        },
      },
      selectAttendant: {
        title: 'Transferir conversa',
      },
      selectEmoticon: {
        title: 'Selecionar emoticon',
      },
      history: {
        title: 'Histórico de conversas - {contact}',
      },
      responsibleAttendant: {
        label: 'Atendente Responsável',
        notAssociated: 'Sem atendente responsável',
      },
      clickToPasteAttachments: 'Clique para anexar via teclado (Ctrl+C Ctrl+V)',
      openConversation: {
        title: 'Iniciar conversa',
      },
      sendQuickAnswer: 'Enviar resposta pronta',
    },
  },

  chatListItem: {
    label: {
      yesterday: 'Ontem',
      you: 'Você:',
    },
    contact: {
      notLocated: 'Contato não localizado',
    },
  },

  chatDialog: {
    dateSeparator: '{day} de {month} de {year}',
    clickToShowContact: 'Clique para ver o contato',
    clickToAddContact: 'Clique para adicionar o contato',
    clickToOpenClient: 'Clique para abrir o Painel de Relacionamento do cliente',
    clickToOpenProspect: 'Clique para abrir o Painel de Relacionamento do prospect',
    label: {
      today: 'Hoje',
      yesterday: 'Ontem',
    },
    imageHeader: 'Imagem do cabeçalho',
  },

  chatDialogMessage: {
    label: {
      template: 'Template',
      forward: 'Encaminhada',
      you: 'Você',
    },
  },

  chatCallerButton: {
    messages: {
      errorOnGetPermissions: 'Houve um erro ao verificar suas permissões para gerenciar Whatsapp. Tente novamente.',
      whatsappDisabled: 'WhatsApp não liberado',
      youDoNotHavePermissiontoManageWhatsApp: 'Você não tem permissão para gerenciar WhatsApp',
      couldNotFoundAValidWaIdForNumber: 'Não foi possível encontrar um WhatsApp válido para o número {contactNumber}',
    },
  },

  chatSelectTemplate: {
    template: 'Template',
  },

  chatBlockNumber: {
    description:
      'Você tem certeza que deseja bloquear o envio e recebimento de mensagens para o número {contactNumber}?',
    reason: 'Se desejar, informe o motivo pelo qual deseja bloquear esse número:',
  },

  chatSelectQuickAnswer: {
    quickAnswer: 'Respostas prontas',
  },

  chatSelectAttendant: {
    attendant: 'Atendente',
  },

  chatSelectDepartment: {
    department: 'Departamento',
  },

  chatContactForm: {
    conversationType: 'Cargo',
    successfullySave: 'Contato salvo com sucesso',
    name: 'Contato',
    contactType: 'Cargo',
    email: 'E-mail',
    telephone: 'Telefone',
    whatsapp: 'WhatsApp',
    birthdate: 'Data de Nascimento',
    active: 'Ativo',
    client: 'Cliente',
    prospect: 'Prospect',
    createProspect: 'Novo Prospect',
    searchForClient: 'Pesquise por um cliente',
    searchOrCreateProspect: 'Pesquise ou cadastre um prospect',

    label: {
      client: 'Cliente',
      prospect: 'Prospect',
    },

    messages: {
      selectAClientToSaveContact: 'Selecione um cliente antes de salvar o contato',
      selectAProspectToSaveContact: 'Selecione um prospect antes de salvar o contato',
    },

    dialog: {
      prospect: {
        title: 'Novo Prospect',
      },
    },
  },

  callCenterContactForm: {
    callType: 'Tipo de Conversa',
    successfullySave: 'Contato salvo com sucesso',
    name: 'Contato',
    contactType: 'Tipo de Contato',
    email: 'E-mail',
    telephone: 'Telefone',
    whatsapp: 'WhatsApp',
    birthdate: 'Data de Nascimento',
    active: 'Ativo',
    client: 'Cliente',
    prospect: 'Prospect',
    createProspect: 'Novo Prospect',
    searchForClient: 'Pesquise por um cliente',
    searchOrCreateProspect: 'Pesquise ou cadastre um prospect',

    label: {
      client: 'Cliente',
      prospect: 'Prospect',
    },

    messages: {
      selectAClientToSaveContact: 'Selecione um cliente antes de salvar o contato',
      selectAProspectToSaveContact: 'Selecione um prospect antes de salvar o contato',
    },

    dialog: {
      prospect: {
        title: 'Novo Prospect',
      },
    },
  },

  chatCloseForm: {
    description: 'Descrição',
    mistake: 'Engano',
    origin: 'Origem',
    historyType: 'Tipo de Histórico',
    group: 'Grupo/Área',
    consolidatedSale: 'Efetuou venda',
    messages: {
      successfullyClose: 'Conversa fechada com sucesso',
      successfullyTransfered: 'Conversa transferida com sucesso',
      couldNotFoundAttendantToTransferConversation: 'Não foi possível encontrar o atendente para transferir a conversa',
    },
  },

  chatHistoryViewer: {
    grid: {
      protocol: 'Protocolo',
      attendant: 'Atendente',
      openDate: 'Data início conversa',
      closeDate: 'Data de fechamento',
      type: 'Tipo de histórico',
      consolidateSale: 'Efetuou venda',
    },
    messages: {
      couldNotFoundWhatsappNumber: 'Não foi possível encontrar o número do Whatsapp para iniciar a pesquisa',
    },
    dialog: {
      messages: {
        title: 'Mensagens da conversa - {protocol}',
      },
    },
  },

  chatHistoryMessagesViewer: {
    showMoreItems: 'Mostrar mais itens',
    dateSeparator: '{day} de {month} de {year}',
    clickHereToExportConversation: 'Clique aqui para exportar a conversa',
    clickHereToViewProtocol: 'Clique aqui para ver o histórico da conversa antes da transferência',
    label: {
      protocol: 'Número do protocolo',
      department: 'Departamento',
      attendant: 'Atendente',
      openDate: 'Data início conversa',
      finishDate: 'Data de fechamento',
      mistake: 'Engano',
      origin: 'Origem',
      type: 'Tipo de histórico',
      group: 'Groupo/área',
      description: 'Descrição',
      consolidateSale: 'Efetuou venda',
    },
  },

  chatMediaPreview: {
    addACaption: 'Adicione uma legenda...',
    type: {
      photo: 'Foto',
      video: 'Vídeo',
      document: 'Documento',
      unknown: 'Desconhecido',
    },
  },

  prospectForm: {
    successfullySave: 'Prospect salvo com sucesso',
    cityRepresentatives: 'Representantes que atuam no município selecionado',
    otherRepresentatives: 'Demais representantes',
    consultSintegra: 'Consultar Sintegra',
    consultCorreios: 'Buscar CEP',
    informCnpjAndStateSintegra: 'Necessário informar CNPJ e Estado para realizar a Consulta Sintegra',
    informCep: 'Necessário informar o CEP {completo} para realizar a consulta do endereço',
    cnpj: 'CNPJ / CPF',
    name: 'Nome Fantasia',
    legalName: 'Razão Social',
    contact: 'Contato',
    telephone: 'Telefone',
    whatsapp: 'WhatsApp',
    email: 'Email',
    facebook: 'Facebook',
    instagram: 'Instagram',
    cep: 'CEP',
    address: 'Endereço',
    number: 'Número',
    neighborhood: 'Bairro',
    complement: 'Complemento',
    city: 'Cidade',
    state: 'Estado',
    stateInscription: 'Inscrição Estadual',
    suframa: 'Suframa',
    situation: 'Situação',
    representative: 'Representante',
    observation: 'Observações',
    lead: 'Lead',
    leadType: 'Tipo de lead',
    contactChannel: 'Forma de captação',
    label: {
      registration: 'Inclusão',
      change: 'Alteração',
    },
    status: {
      active: 'Ativo',
      inactive: 'Inativo',
      converted: 'Convertido',
    },
    dialog: {
      confirmSave:
        'Identificamos a existência de um cliente com esse mesmo CNPJ!<br><br> Cliente: {nomeCliente}<br> Código: {codCliente}<br><br> Você deseja cadastrar esse prospect mesmo assim?',
      cnpjExistingClient:
        'Não é possível cadastrar esse prospect pois já existe um cadastro de clientes com esse mesmo CNPJ!<br><br> Cliente: {nomeCliente}<br> Código: {codCliente}<br><br>',
    },
    warn: {
      phoneIsRequired: 'O preenchimento do campo telefone/celular é obrigatório',
      emailIsRequired: 'O preenchimento do campo e-mail é obrigatório!',
    },
    optionConverted: 'Para converter um prospect em cliente, selecione o botão Tranformar em cliente presente no painel de relacionamento.',
  },

  clientSearchField: {
    client: 'Cliente',
    prospect: 'Prospect',
    searchForAClient: 'Pesquise por um cliente',
    searchForAProspect: 'Pesquise por um prospect',
  },

  addToServiceQueuQueueForm: {
    client: 'Cliente',
    prospect: 'Prospect',
    serviceQueue: 'Fila de atendimento',
    observation: 'Observação',
    successfullyAdded: '{clientType} adicionado com sucesso',
  },

  callCenter: {
    dialog: {
      contact: {
        title: 'Contato',
      },
    },
    messages: {
      wouldYouSaveContactOrCloseAsMistake:
        'Atenção! Contato não cadastrado. Deseja salvar o contato ou fechar a ligação como engano?',
      requiredLoginToUsePainel: 'Efetue seu login para poder utilizar o painel de relacionamento.',
      failedChangeStatusDuringCall:
        'Não é possível realizar a alteração de status pois seu usuário possui um ligação em andamento.',
    },

    newCallDialog: {
      returnCall: 'Retornando Ligação',
      newCall: 'Nova Ligação',
      callInProgress: 'Ligação em andamento',
      identifiedCustomer: 'Cadastro identificado',
      identifyingCustomer: 'Identificando Contato',
      answer: 'ATENDER',
      decline: 'RECUSAR',
      transfer: 'TRANSFERIR',
      addContact: 'ADICIONAR CONTATO',
      finish: 'FINALIZAR',

      contactNotFound: 'CONTATO NÃO ENCONTRADO',
    },

    callInProgress: {
      newCall: 'Nova Ligação',
      callInProgress: 'Ligação em andamento',
      identifiedCustomer: 'Cadastro identificado',
      answer: 'ATENDER',
      decline: 'RECUSAR',
      transfer: 'TRANSFERIR',
      addContact: 'ADICIONAR CONTATO',
      finish: 'FINALIZAR',

      contactNotFound: 'CONTATO NÃO ENCONTRADO',
    },

    loginSection: {
      title: 'Área de login',
      userLogin: 'LOGIN DO USUÁRIO',
      subtitle: 'Preencha os campos abaixo com seus dados de acesso ao Vocal Phone:',
      fields: {
        ramal: 'Ramal',
        id: 'Id',
        password: 'Senha',
      },

      greetingMessage: 'Olá, {username} :)',

      btns: {
        pause: 'fazer uma pausa!',
        logout: 'deslogar',
        return: 'Despausar',
      },
    },

    breakReasonsDialog: {
      title: 'Motivo da pausa',
      reason: 'Motivo',
    },

    transferCallDialog: {
      title: 'Transferência de Atendente',
      attendants: 'Atendente',
      transfer: 'TRANSFERIR',
      messages: {
        notFound: 'Não foi encontrada uma chamada para avaliação.',
        success: 'Ligação transferida com sucesso!',
        userUnavailable: 'Você não pode transferir uma chamada para usuários indisponíveis.',
      },
    },

    userStatusDialog: {
      title: 'Status Atendentes',
      grid: {
        username: 'Usuário',
        status: 'Status',
        lastBreak: 'Última pausa',
      },
    },

    finishCallDialog: {
      title: 'Finalizar ligação',
      description: 'Descrição',
      origin: 'Origem',
      historyType: 'Tipo de Histórico',
      group: 'Grupo/Área',
      result: 'Resultado',
      resultOptions: {
        ENGANO_DUVIDAS: 'Engano/Dúvidas',
        ATIVA: 'Ligação concluída',
        NAO_SUCEDIDA: 'Ligação não sucedida',
        PERDIDA: 'Ligação perdida',
      },
      messages: {
        notFound: 'Não foi encontrada uma chamada para avaliação.',
        success: 'Ligação finalizada!',
      },
    },

    queueSection: {
      title: 'Minha fila',
      statusQueue: {
        oneCall: '{numberCall} Ligação em fila!',
        twoOrMoreCall: '{numberCall} Ligações em fila!',
        noCall: 'Não há ligações nessa fila no momento!',
      },
    },

    returnSection: {
      title: 'Retornar',
      statusCallBack: {
        oneCall: '{numberCall} Ligação perdida',
        twoOrMoreCall: '{numberCall} Ligações perdidas',
        noCall: 'Não há ligações perdidas no momento!',
      },
      btn: {
        return: 'Retornar',
      },
    },

    inProgressSection: {
      title: 'Em Andamento',
    },

    historyCall: {
      number: 'Número',
      contact: 'Contato',
      callType: 'Tipo de ligação',
      totalCallTime: 'Tempo total da ligação',
      group: 'Grupo/Área',
      historyType: 'Tipo de histórico',
      description: 'Descrição',
      totalCallTimeFormat: {
        hour: '{hour} hora ',
        hours: '{hour} horas ',
        minute: '{minute} minuto e ',
        minutes: '{minute} minutos e ',
        second: '{second} segundo ',
        seconds: '{second} segundos ',
      },
    },
  },
};
